<template lang="html">
  <div
    :class="{ 'is-active': showProfileOverlay }"
    class="u-bg--sand c-overlay-scr c-overlay-scr--top c-overlay-scr--header"
  >
    <template v-if="isRendered">
      <div class="c-overlay-scr__header">
        <div class="c-overlay-scr__title">Profile Menu</div>
        <div class="c-overlay-scr__logo" style="display: none">
          <a
            href="/"
            title="Belanja Online Aman, Nyaman dan Terpercaya di Bukalapak"
            class="c-overlay-scr__logo-image"
            >Bukalapak.com</a
          >
        </div>
        <div class="c-overlay-scr__action">
          <button class="btn-close btn-icon" @click="closeProfileOverlay">
            <span class="c-icon c-icon--close" />
          </button>
        </div>
      </div>

      <div class="c-overlay-scr__content" style="height: calc(100% - 60px)">
        <div class="c-panel c-panel--full u-no-border--top u-mrgn-bottom--0">
          <div class="c-panel__item--bleed">
            <div class="c-user-panel__cover" />
          </div>
        </div>

        <div class="c-panel__item u-pad--4 u-margin-bottom--small">
          <div class="o-flag o-flag--small">
            <div class="o-flag__head">
              <img
                :src="avatar"
                size="50"
                class="c-avatar c-avatar--medium c-avatar__profile-menu o-flag__img"
              />
            </div>
            <div class="o-flag__body">
              <div
                class="u-fg--black u-txt--base u-txt--bolder u-ellipsis-block"
              >
                {{ name }}
              </div>
              <div class="u-fg--ash-dark u-txt--small">{{ username }}</div>
            </div>
          </div>
        </div>

        <div
          class="c-panel c-panel--full u-no-border--top u-margin-bottom--small"
        >
          <div class="c-panel__row">
            <a
              href="/messages?from=user_panel"
              class="c-panel__item c-panel__item--1of2 u-text-center u-fg--black"
            >
              <p class="u-margin-bottom--tiny">
                <span
                  class="c-sprite-icon c-sprite-icon--medium c-sprite-icon--chat"
                />
              </p>
              Pesan
            </a>

            <a
              href="/payment/invoices?from=user_panel"
              class="c-panel__item c-panel__item--1of2 u-text-center u-fg--black"
            >
              <p class="u-margin-bottom--tiny">
                <span
                  class="c-sprite-icon c-sprite-icon--medium c-sprite-icon--transaction c-icon--with-bubble"
                />
              </p>
              Transaksi
            </a>
          </div>

          <div class="c-panel__row">
            <a
              href="/dompet?from=user_panel"
              class="c-panel__item c-panel__item--1of2 u-text-center u-fg--black"
            >
              <p class="u-margin-bottom--tiny">
                <span
                  class="c-sprite-icon c-sprite-icon--medium c-sprite-icon--wallet"
                />
              </p>
              BukaDompet
            </a>

            <a
              href="/bookmarks?from=user_panel"
              class="c-panel__item c-panel__item--1of2 u-text-center u-fg--black"
            >
              <p class="u-margin-bottom--tiny">
                <span
                  class="c-icon c-icon--favorite-outline u-txt--xlarge u-fg--red-brand"
                />
              </p>
              Barang Favorit
            </a>
          </div>
        </div>

        <MenuSection :menus="section1" />

        <CollapsibleMenuSection :menus="section2" />

        <MenuSection :menus="section3" />
      </div>
    </template>
  </div>
</template>

<script>
// cannot use alias shared_js, it will not be compiled
import { runtimeEnv } from '../../../../shared/javascripts/runtime-parser';
import MenuSection from './MenuSection.vue';
import CollapsibleMenuSection from './CollapsibleMenuSection.vue';

export default {
  name: 'ProfileOverlay',
  components: {
    MenuSection,
    CollapsibleMenuSection,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    section1: [
      {
        label: 'Bukalapak Credits',
        href: '/credits?from=user_panel',
        icon: 'c-sprite-icon--bl-credit',
      },
      {
        label: 'BukaReksa',
        href: '/bukareksa?desktop_view=1',
        icon: 'c-sprite-icon--bukareksa',
      },
      {
        label: 'Daftar Mitra',
        href: '/daftar_agen',
        icon: 'c-sprite-icon--agent-register',
      },
    ],
    section2: [
      {
        label: 'Menu Pelapak',
        icon: 'c-sprite-icon--store_alt',
        subMenus: [
          {
            label: 'Jual Barang',
            href: '/products/new?from=user_panel',
          },
          {
            label: 'Ringkasan Akun',
            href: '/my_lapak?from=user_panel',
          },
          {
            label: 'Barang Dijual',
            href: '/my_products/stocked?from=user_panel',
          },
          {
            label: 'Barang Tidak Dijual',
            href: '/my_products/stockless?from=user_panel',
          },
          {
            label: 'Barang Draf',
            href: '/products/draft?from=user_panel',
          },
          {
            label: 'Etalase Barang',
            href: '/labels/labels/organize?from=user_panel',
          },
          {
            label: 'Beli Paket Push',
            href: '/push_package_histories/new?from=user_panel',
          },
          {
            label: 'Promoted Push',
            href: '/deposit/sem_budgets?from=user_panel',
          },
          {
            label: 'Karyawan Lapak',
            href: '/karyawan-lapak',
          },
          {
            label: 'Jadwal Pengiriman',
            href: '/users/last_order_setting?from=user_panel',
          },
          {
            label: 'Batas Waktu Input Resi',
            href: '/users/input_receipt_setting?from=user_panel',
          },
          {
            label: 'Feedback',
            href: '/recomendations?from=user_panel',
          },
        ],
      },
      {
        label: 'Pengaturan',
        icon: 'c-sprite-icon--cogs',
        subMenus: [
          {
            label: 'Akun',
            href: '/account_settings?from=user_panel',
          },
          {
            label: 'Alamat',
            href: '/user_addresses?from=user_panel',
          },
          {
            label: 'Lapak',
            // TODO
            href: '/users/----/store_settings?from=user_panel',
          },
          {
            label: 'Kurir',
            href: '/users/courier_settings?from=user_panel',
          },
          {
            label: 'Jadwal Rocket Delivery',
            href: '/users/rush_delivery_settings?from=user_panel',
          },
          {
            label: 'Rekening Bank',
            href: '/payment/bank_accounts?from=user_panel',
          },
          {
            label: 'Pengaturan Pembayaran',
            href: '/users/payment_settings?from=user_panel',
          },
          {
            label: 'Notifikasi',
            href: '/other_settings?from=user_panel',
          },
          {
            label: 'Sharing',
            href: '/share_settings?from=user_panel',
          },
        ],
      },
      {
        label: 'Lainnya',
        icon: 'c-sprite-icon--others',
        subMenus: [
          {
            label: 'Kode Promo',
            href: '/promo_reward/redeem?from=user_panel',
          },
        ],
      },
    ],
    section3: [
      {
        label: 'Logout',
        href: runtimeEnv.ACCOUNTS_URL
          ? `${runtimeEnv.ACCOUNTS_URL}/logout`
          : 'https://accounts.bukalapak.com/logout',
        icon: 'c-sprite-icon--logout',
      },
    ],
    isRendered: false,
  }),
  computed: {
    showProfileOverlay() {
      return this.$store.state.app.showProfileOverlay;
    },
  },
  watch: {
    showProfileOverlay(val) {
      if (val) {
        this.isRendered = true;
      }
    },
  },
  methods: {
    closeProfileOverlay() {
      this.$store.commit('app/toggleProfileOverlay');
    },
  },
};
</script>

<style lang="scss">
#vm__mobile-header p {
  margin: 0;
  padding: 0;
}

#vm__mobile-header p {
  margin-bottom: 0.85714rem;
}

#vm__mobile-header .o-flag {
  display: table;
  width: 100%;
  border-spacing: 0;

  &__body,
  &__head {
    display: table-cell;
    vertical-align: middle;
  }

  &__head {
    width: 1px;
    padding-right: 24px;
  }

  &__img {
    max-width: none;
  }

  &__body {
    width: auto;
  }

  &__body,
  &__body > :last-child {
    margin-bottom: 0;
  }

  &--small > &__head {
    padding-right: 0.85714rem;
  }
}

#vm__mobile-header .c-avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  width: 2.57143rem;
  height: 2.57143rem;
  border: 1px solid #ddd;
  border-radius: 50%;

  &__image {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__profile-menu {
    border: 2px solid #fff;
  }

  &--medium {
    width: 3rem;
    height: 3rem;
  }
}

#vm__mobile-header .c-icon--with-bubble {
  position: relative;
}

#vm__mobile-header .c-overlay-scr {
  background-color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  will-change: transform;
  transform: translateX(-200%);
  top: 0;
  width: 100%;
  z-index: 999;
  visibility: hidden;
  transition: visibility 0s linear 0.2s, transform 0.2s;

  &.is-active {
    transform: translateX(0);
    visibility: visible;
    transition-delay: 0s;
  }

  &--header {
    z-index: 1001;
  }

  &--top {
    transform: translateY(-100%);
  }

  &--top.is-active {
    transform: translateY(0);
  }

  &__header {
    background-color: #d71149;
    display: table;
    table-layout: fixed;
    height: 4rem;
    width: 100%;
  }

  &__title {
    font-size: 1.14286rem;
    line-height: 1.4;
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }

  &__logo {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }

  &__action {
    position: absolute;
    left: 4px;
    top: 4px;
  }

  &__action .btn-close {
    color: #fff;
    height: 48px;
    width: 48px;
    font-size: 24px;
  }

  &__content {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  &__content__inner {
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 4rem;
    overflow-y: auto;
  }

  &__logo-image {
    display: inline-block;
    width: 122px;
    height: 20px;
    text-indent: -9999px;
    background-image: url(https://s2.bukalapak.com/images/mobile/logo-text-white@2x.png);
    background-size: 122px;
    background-repeat: no-repeat;
  }
}

#vm__mobile-header .btn-icon {
  background: none;
  border: 0;
  -webkit-appearance: none;
}

#vm__mobile-header .c-panel {
  border: 1px solid #eee;
  border-radius: 2px;
  margin-bottom: 12px;
  overflow: hidden;

  &__item {
    background-color: #fff;
    border-top: 1px solid #eee;
    padding: 1.42857rem;
    position: relative;

    &:first-of-type {
      border-top: 0;
    }

    &--bleed {
      padding: 0 !important;
    }
  }

  &__row {
    border-top: 1px solid #eee;
    background: #fff;

    &:after,
    &:before {
      display: table;
      content: ' ';
    }

    &:after {
      clear: both;
    }
  }

  &__row .c-panel__item {
    float: left;
    border-top: 0;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: 0;
    }

    &--1of2 {
      width: 50%;
    }
  }

  &--full {
    border-right: 0;
    border-left: 0;
  }

  &--full .c-panel__item {
    padding-left: 1.71429rem;
    padding-right: 1.71429rem;
  }
}

#vm__mobile-header .c-user-panel {
  &__cover {
    position: relative;
    width: 100%;
    padding-bottom: 54.6875%;
    overflow-y: hidden;
    background: #fff
      url(https://s4.bukalapak.com/images/mobile/illustrations/ill-default-cover@2x.png)
      no-repeat;
    background-size: cover;
  }

  &-nav {
    background: #fff;
    clear: both;
    margin-left: 0;
    list-style: none;
    padding-left: 20px;
  }

  &-nav__item {
    margin: 0;
    padding-left: 0.28571rem;
  }

  &-nav__link {
    font-size: 1rem;
    line-height: 1.4;
    padding-top: 1.14286rem;
    display: block;
    position: relative;
    color: #333;
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
  }

  &-nav__item--has-subnav.subnav-active .c-user-panel-nav__link__icon--right {
    transform: rotate(180deg);
  }

  &-nav__item--has-subnav.subnav-active .c-user-panel-nav {
    max-height: 2000px;
  }

  &-nav__link__icon {
    float: left;
    font-size: 1.71429rem;
    margin-top: -2px;
  }

  &-nav__link__icon--right {
    padding: 1.14286rem;
    font-size: 1.28571rem;
    line-height: 1.2;
    position: absolute;
    top: 0;
    right: 0;
  }

  &-nav__link__label {
    border-bottom: 1px solid #eee;
  }

  &-nav__link__label {
    padding-bottom: 1.14286rem;
    padding-right: 2.85714rem;
    display: block;
    margin-left: 32px;
  }

  &-nav--subnav {
    margin-bottom: 0;
    max-height: 0;
    transition: max-height 0.2s;
    overflow-y: hidden;
  }
}

#vm__mobile-header .u {
  &-text-center {
    text-align: center !important;
  }

  &-margin-bottom--tiny {
    margin-bottom: 0.57143rem !important;
  }

  &-margin-bottom--small {
    margin-bottom: 0.85714rem !important;
  }

  &-fg--ash-dark,
  &-fg--ash-dark:focus,
  &-fg--ash-dark:hover {
    color: #999 !important;
  }

  &-fg--red-brand,
  &-fg--red-brand:focus,
  &-fg--red-brand:hover {
    color: #d71149 !important;
  }

  &-txt--bolder {
    font-weight: 700;
  }

  &-txt--small {
    font-size: 0.85714rem;
    line-height: 1.4;
  }

  &-txt--xlarge {
    font-size: 1.7142857143rem;
  }

  &-mrgn-bottom--0 {
    margin-bottom: 0 !important;
  }

  &-pad-left--3 {
    padding-left: 0.85714rem !important;
  }

  &-pad--4 {
    padding: 1.14286rem !important;
  }

  &-no-border--top {
    border-top: 0 !important;
  }

  &-block {
    display: block !important;
  }

  &-ellipsis-block {
    font-size: 1rem;
    line-height: 1.4;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
