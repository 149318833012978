<template lang="pug">
ul(:class='classObj')
  SideDrawerNavItem(v-for='(item, $id) in items' :key='$id' v-bind='item')
</template>

<script>
export default {
  name: 'SideDrawerNav',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    subnav: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classObj() {
      return {
        'c-side-drawer-nav': true,
        'c-side-drawer-nav--subnav': this.subnav,
      };
    },
  },
};
</script>
