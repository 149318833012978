<template lang="pug">
include ./svg-mixin.pug
div
  header.c-header.c-header--red.c-header--fixed
    .branch-journeys-top
    .c-header__action
      button.c-header__action__btn(aria-label="menu" @click="openSideDrawer")
        +svgFromSinglePath(24, 24, "#FFF", "M21.5 7h-19a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h19a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5zm.5 5.5v-1a.5.5 0 0 0-.5-.5h-19a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5zm0 6v-1a.5.5 0 0 0-.5-.5h-19a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5z")

      a.c-header__action__btn(href="https://m.bukalapak.com" aria-label="home" title="Belanja Online Aman, Nyaman dan Terpercaya di Bukalapak")
        img.c-header__action__logo(src="https://s0.bukalapak.com/marketplace/images/logo/bukalapak-icon-secondary.png" alt='Bukalapak Logo' title='Bukalapak Logo' width='30')

    .c-header__action.c-header__action--right
      button.c-header__action__btn(aria-label="search" @click="openOmnisearch")
        +svgFromSinglePath(24, 24, "#FFF", "M20.71 19.29l-4.4-4.39A7.92 7.92 0 0 0 18 10a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l4.39 4.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM4 10a6 6 0 1 1 6 6 6 6 0 0 1-6-6z")

      button.c-header__action__btn.button-login(:aria-label="isLoggedIn ? 'account' : 'login'" @click="handleButtonLoginClick")
        template(v-if="isLoggedIn")
          +svgFromSinglePath(24, 24, "#FFF", "M16 7a4 4 0 1 1-4-4 4 4 0 0 1 4 4zm4 9.88a3 3 0 0 0-1.92-2.81A17.28 17.28 0 0 0 12 13a17.28 17.28 0 0 0-6.08 1.07A3 3 0 0 0 4 16.88v3.62a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5z")
        template(v-else)
          +svgFromSinglePath(24, 24, "#FFF", "M21 3v18a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-3a1 1 0 0 1 2 0v2h11V4H8v2a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1zM10.29 15.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4-4a1 1 0 0 0 0-1.42l-4-4a1 1 0 1 0-1.42 1.42l2.3 2.29H4a1 1 0 0 0 0 2h8.59z")

      a.c-header__action__btn.u-position-relative(href="/cart/carts" aria-label="cart" title="Keranjang Belanja")
        +svgFromSinglePath(24, 24, "#FFF", "M9 20a2 2 0 1 1-2-2 2 2 0 0 1 2 2zm9-7a1 1 0 0 0 .92-.61l3-7A1 1 0 0 0 21 4H6.39L6 2.68A1 1 0 0 0 5 2H2v2h2l.34.17 2.48 7.45-1.6 3.21a1.49 1.49 0 0 0 .06 1.46 1.52 1.52 0 0 0 1.28.71H20v-2H7.37l1-2zm-1 5a2 2 0 1 0 2 2 2 2 0 0 0-2-2z")

        menu-counter(v-if="totalCartItems && isMounted" :counter="totalCartItems")

  side-drawer

  div(v-if="isLoggedIn && user.username && isMounted")
    profile-overlay(:username="user.username" :name="user.name" :avatar="user.avatarURL")
</template>
<script>
import ProfileOverlay from './components/ProfileOverlay';
import SideDrawer from './components/SideDrawer/SideDrawer';
import MenuCounter from './components/MenuCounter';

export default {
  components: {
    ProfileOverlay,
    SideDrawer,
    MenuCounter,
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    user() {
      return global.USER || {};
    },
    isLoggedIn() {
      return this.user.id;
    },
    totalCartItems() {
      return global.STORE ? global.STORE.state.cart.items.length : 0;
    },
  },
  mounted() {
    this.isMounted = true;

    global.STORE.dispatch('cart/getCarts').catch(console.error); // eslint-disable-line
  },
  methods: {
    openSideDrawer() {
      this.$store.commit('app/toggleSideDrawer');
    },
    openOmnisearch() {
      global.STORE.commit('omnisearch/toggleShowOverlay');
    },
    openProfileOverlay() {
      this.$store.commit('app/toggleProfileOverlay');
    },
    handleButtonLoginClick() {
      if (this.isLoggedIn) {
        this.openProfileOverlay();
      } else {
        this.loginPage();
      }
    },
    loginPage() {
      window.location = '/login';
    },
    assetUrl: global.assetUrl,
  },
};
</script>
