<template lang="html">
  <div class="c-panel c-panel--full u-margin-bottom--tiny">
    <a v-for="menu in menus" 
       :key="menu.href" 
       :href="menu.href" 
       class="c-panel__item u-block u-fg--black">
      <span :class="`c-sprite-icon c-sprite-icon--medium ${menu.icon}`" />
      <span class="u-pad-left--3">{{ menu.label }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'MenuSection',
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
};
</script>
