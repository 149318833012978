<template lang="pug">
.c-side-drawer__header
  a.c-side-drawer__logo-new(href='/')
    img(src='https://s2.bukalapak.com/marketplace/images/logo/bukalapak-icon-secondary.png' alt='Bukalapak Logo' title='Bukalapak Logo' height='32' width='32')
  .c-side-drawer__app-download
    span Download Aplikasinya
    a.c-side-drawer__app-download__btn(:href='appLink' target='_blank' rel='noopener') Install
</template>

<script>
import { isHuaweiUserAgent } from '../../../../shared/javascripts/useragent-helper';

export default {
  data() {
    return {
      appLink: 'https://bl.app.link/AmspQx0MxK',
    };
  },
  created() {
    this.appLink = isHuaweiUserAgent()
      ? 'https://appgallery.cloud.huawei.com/ag/n/app/C103354495?locale=en_GB&source=appshare&subsource=C103354495'
      : this.appLink;
  },
};
</script>
