import createApp from './createApp';
import { MOUNT_POINT_ID } from './constants';

const { Vue, Vuex } = window;

const { app, store } = createApp({
  Vue,
  Vuex,
});

const initialState = window.__HEADER_MOBILE_STATE__;

if (initialState && initialState.category && initialState.category.tree) {
  store.commit('category/setCategoryTree', initialState.category.tree);
}

app.$mount(`#${MOUNT_POINT_ID}`, true);
