<template lang="pug">
.c-side-drawer__content
  .c-side-drawer__content__inner
    SideDrawerMainNav(v-if="nav === 'main'")
    SideDrawerCategoryNav(:class="{ 'c-side-drawer__content__inner__hidden': nav !== 'category' }")
    SideDrawerVPNav(v-if="nav === 'vp'")
</template>

<script>
import SideDrawerMainNav from './SideDrawerMainNav.vue';
import SideDrawerCategoryNav from './SideDrawerCategoryNav.vue';
import SideDrawerVPNav from './SideDrawerVPNav.vue';

export default {
  name: 'SideDrawerContent',
  components: {
    SideDrawerMainNav,
    SideDrawerCategoryNav,
    SideDrawerVPNav,
  },
  computed: {
    nav() {
      return this.$store.state.app.sideDrawerNav;
    },
  },
};
</script>
