<template lang="pug">
SideDrawerNav(:items="navItems")
</template>

<script>
export default {
  name: 'SideDrawerVPNav',
  data: () => ({
    navItems: [
      {
        label: 'Menu Utama',
        labelClass: 'qa-main-menu',
        iconLeft: 'c-icon c-icon--chevron-left',
        onClick: function backToMain(e) {
          e.preventDefault();
          this.$store.commit('app/setSideDrawerNav', 'main');
        },
      },
      {
        label: 'Pulsa Prabayar',
        href: 'https://m.bukalapak.com/pulsa?from=mweb_sidedrawer',
      },
      {
        label: 'Pulsa Pascabayar',
        href: 'https://m.bukalapak.com/pulsa/pascabayar?from=mweb_sidedrawer',
      },
      {
        label: 'Paket Data',
        href: 'https://m.bukalapak.com/paket-data?from=mweb_sidedrawer',
      },
      {
        label: 'Token Listrik',
        href: 'https://m.bukalapak.com/listrik-pln/token-listrik?from=mweb_sidedrawer',
      },
      {
        label: 'Listrik Pascabayar',
        href: 'https://m.bukalapak.com/listrik-pln/tagihan-listrik?from=mweb_sidedrawer',
      },
      {
        label: 'PLN Non-Taglis',
        href: 'https://m.bukalapak.com/listrik-pln/non-taglis?from=mweb_sidedrawer',
      },
      {
        label: 'Voucher Game',
        href: 'https://m.bukalapak.com/voucher-game?from=mweb_sidedrawer',
      },
      {
        label: 'Tiket Kereta',
        href: 'https://m.bukalapak.com/kereta-api?from=mweb_sidedrawer&desktop_view=1',
      },
      {
        label: 'Tiket Pesawat',
        href: 'https://m.bukalapak.com/tiket-pesawat?from=mweb_sidedrawer&desktop_view=1',
      },
      {
        label: 'Air PDAM',
        href: 'https://m.bukalapak.com/air-pdam?from=mweb_sidedrawer&desktop_view=1',
      },
      {
        label: 'BPJS Kesehatan',
        href: 'https://m.bukalapak.com/bpjs-kesehatan?from=mweb_sidedrawer&desktop_view=1',
      },
      {
        label: 'Tagihan Kartu Kredit',
        href: 'https://m.bukalapak.com/tagihan-kartu-kredit?from=mweb_sidedrawer&desktop_view=1',
      },
      {
        label: 'Angsuran Kredit',
        href: 'https://m.bukalapak.com/angsuran-kredit?from=mweb_sidedrawer&desktop_view=1',
      },
      {
        label: 'Telkom/IndiHome',
        href: 'https://m.bukalapak.com/telkom-indihome?from=mweb_sidedrawer&desktop_view=1',
      },
      {
        label: 'TV Kabel',
        href: 'https://m.bukalapak.com/tv-kabel?from=mweb_sidedrawer',
      },
      {
        label: 'Berita',
        labelClass: 'qa-sidebar-news-entry-point',
        href: 'http://bukalapak.liputan6.com',
        openNewTab: true,
      },
    ],
  }),
};
</script>
