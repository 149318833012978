<template lang="pug">
SideDrawerNav(:items="navItems")
</template>

<script>
export default {
  name: 'SideDrawerCategoryNav',
  computed: {
    navItems() {
      return [
        // Back to main
        {
          label: 'Menu Utama',
          iconLeft: 'c-icon c-icon--chevron-left',
          onClick: function backToMain(e) {
            e.preventDefault();
            this.$store.commit('app/setSideDrawerNav', 'main');
          },
        },

        // Category tree
        ...this.$store.state.category.tree.map(category => ({
          label: category.name,
          iconRight: 'c-icon c-icon--chevron-right',
          href: `/c/${category.permalink}`,
          subnav: this.getSubnav(category),
        })),
      ];
    },
  },
  mounted() {
    if (this.$store.state.category.tree.length === 0) {
      this.$store.dispatch('category/fetchCategories').catch(this.logError);
    }
  },
  methods: {
    getSubnav(cat, showDiscount = false) {
      let subnav = [];

      if (cat.children.length) {
        // Parent category
        subnav.push({
          label: cat.name,
          href: `/c/${cat.permalink}`,
          labelClass: 'u-txt--bold',
        });

        // Discount
        if (showDiscount) {
          subnav.push({
            label: `Diskon ${cat.name}`,
            href: `https://m.bukalapak.com/diskon/${cat.permalink}`,
          });
        }

        subnav = [
          ...subnav,

          // Children categories
          ...cat.children.map(child => {
            const href = `/c/${child.permalink}`;
            const hasSubnav = child.children.length > 0;

            return {
              href,
              label: child.name,
              iconRight: hasSubnav ? 'c-icon c-icon--chevron-right' : null,
              subnav: this.getSubnav(child),
            };
          }),
        ];
      }

      return subnav;
    },
  },
};
</script>
