<template>
  <i class="c-icon__bubble c-icon__bubble--counter" style="top: 4px; right:4px;">{{ counter }}</i>
</template>

<script>
export default {
  props: {
    counter: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
#vm__mobile-header .c-icon__bubble {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: #ffc53e;

  &--counter {
    top: -6px;
    right: -6px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: #333;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Roboto, Segoe UI, Oxygen, Ubuntu, Cantarell,
      Open Sans, sans-serif;
    text-align: center;
    font-style: normal;
  }
}
</style>
