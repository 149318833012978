import App from './App';
import createStore from './store';
import SideDrawerNav from './components/SideDrawer/SideDrawerNav/SideDrawerNav';
import SideDrawerNavItem from './components/SideDrawer/SideDrawerNav/SideDrawerNavItem';
import { MOUNT_POINT_ID } from './constants';

export default function createApp({ Vue, Vuex, USER = {} }) {
  Vue.use(Vuex);

  const store = new Vuex.Store(createStore({ API: global.API }));

  Vue.component('SideDrawerNav', SideDrawerNav);
  Vue.component('SideDrawerNavItem', SideDrawerNavItem);

  const app = new Vue({
    store,
    components: {
      App,
    },
    data() {
      return {
        user: USER,
      };
    },
    template: `<div id="${MOUNT_POINT_ID}"><app/></div>`,
  });

  return { app, store };
}
