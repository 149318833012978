<template lang="html">
  <div class="c-panel u-margin-bottom--tiny">
    <ul class="c-user-panel-nav">
      <li
        v-for="(menu, $idx) in menus"
        :key="$idx"
        :class="{ 'subnav-active': activeIdx === $idx }"
        class="c-user-panel-nav__item c-user-panel-nav__item--has-subnav"
      >
        <a class="c-user-panel-nav__link" @click.prevent="selectMenu($idx)">
          <span :class="`c-user-panel-nav__link__icon c-sprite-icon c-sprite-icon--medium ${menu.icon}`" />
          <span class="c-user-panel-nav__link__label u-pad-left--3">{{ menu.label }}</span>
          <span class="c-user-panel-nav__link__icon--right side-drawer-subnav__indicator c-icon c-icon--chevron-down" />
        </a>
        <ul class="c-user-panel-nav c-user-panel-nav--subnav">
          <li v-for="(subMenu, $subIdx) in menu.subMenus" :key="$subIdx" class="c-user-panel-nav__item">
            <a :href="subMenu.href" class="c-user-panel-nav__link">
              <span class="c-user-panel-nav__link__label">{{ subMenu.label }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CollapsibleMenuSection',
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeIdx: -1,
    };
  },
  methods: {
    selectMenu(selectedIdx) {
      if (selectedIdx === this.activeIdx) {
        this.activeIdx = -1;
      } else {
        this.activeIdx = selectedIdx;
      }
    },
  },
};
</script>
