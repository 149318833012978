<template lang="pug">
.c-side-drawer(:class="{ 'c-side-drawer--active': show }")
  .branch-journeys-top
  a.c-side-drawer__close(@click.prevent='close')
    span.c-icon.c-icon--close
  .c-side-drawer__wrapper
    side-drawer-head(v-if='isRendered')
    side-drawer-content
</template>

<script>
import SideDrawerHead from './SideDrawerHead.vue';
import SideDrawerContent from './SideDrawerContent.vue';

export default {
  name: 'SideDrawer',
  components: {
    SideDrawerHead,
    SideDrawerContent,
  },
  data() {
    return {
      isRendered: false,
    };
  },
  computed: {
    show() {
      return this.$store.state.app.showSideDrawer;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.isRendered = true;
      }
    },
  },
  methods: {
    close() {
      this.$store.commit('app/toggleSideDrawer');
    },
  },
};
</script>

<style lang="scss">
#vm__mobile-header ul {
  margin: 0;
  padding: 0;
}

#vm__mobile-header ul {
  margin-left: 1.14286rem;

  > li {
    padding-left: 0.57143rem;
    margin-bottom: 0.57143rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

#vm__mobile-header .c-side-drawer {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  visibility: hidden;

  transition: visibility 0s linear 0.2s, background-color 0.2s;

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(-200%);
    padding-left: 56px;

    transition: visibility 0s linear 0.2s, transform 0.2s ease-out;
    visibility: hidden;
  }

  &__close {
    padding: 0.57143rem 1.14286rem;

    display: block;
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    font-size: 1.71429rem;
  }

  &__header {
    padding: 0.28571rem;
    box-sizing: border-box;
    height: 56px;
    background-color: #d71149;
    color: #fff;
  }

  &__logo-new {
    width: 48px;
  }

  &__logo,
  &__logo-new {
    padding: 0.57143rem;
    display: inline-block;
    color: #fff;
    font-size: 2.14286rem;
    line-height: 0;
  }

  &__app-download {
    font-size: 0.85714rem;
    line-height: 1.4;
    display: inline-block;
    vertical-align: 3px; // Align with logo

    &__btn {
      margin-left: 0.85714rem;
      padding: 0.28571rem 0.57143rem;
      display: inline-block;
      border-radius: 2px;
      border: solid 1px #fff;
      color: #fff;
      font-weight: bold;
    }
  }

  &__content {
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 56px;
  }

  &__content__inner {
    padding: 0.85714rem;
    padding-right: 0;
    height: 100%;
    overflow-y: scroll;

    &__hidden {
      display: none;
    }
  }

  &-nav {
    margin-bottom: 2.28571rem;
    clear: both;
    margin-left: 0;
    list-style: none;

    &__item {
      margin: 0;
      padding-left: 0.28571rem;
    }

    &__item--has-subnav {
      &.subnav-active {
        > .c-side-drawer-nav__link > .c-side-drawer-nav__link__icon--right {
          transform: rotate(180deg);
        }
        > .c-side-drawer-nav {
          max-height: 2000px;
        }
        .c-side-drawer-nav--others {
          max-height: 200px;
        }
      }
    }

    &__link {
      font-size: 1rem;
      line-height: 1.4;
      padding-top: 1.14286rem;
      display: block;
      position: relative;
      color: #333;
      -webkit-tap-highlight-color: transparent;
      -moz-tap-highlight-color: transparent;

      &__icon {
        float: left;
        font-size: 1.71429rem;
        margin-top: -2px; // Align with text
        line-height: 1;
      }

      &__icon--right {
        padding: 1.14286rem;
        font-size: 1.28571rem;
        line-height: 1.2;
        position: absolute;
        top: 0;
        right: 0;
      }

      &__label {
        padding-bottom: 1.14286rem;
        padding-right: 2.85714rem;
        display: block;
        border-bottom: 1px solid #eee;
        margin-left: 32px;
      }

      &__badge {
        margin-right: 0.57143rem;

        float: right;
        margin-top: 2px; // Align with text
      }
    }

    // Subnav
    &--subnav {
      margin-bottom: 0;
      max-height: 0;
      transition: max-height 0.2s;
      overflow-y: hidden;

      .c-side-drawer-nav__item {
        margin-left: 0.85714rem;
      }
    }
  }

  // Active modifier
  &--active {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: visible;
    transition-delay: 0s;

    .c-side-drawer__wrapper {
      transition-delay: 0s;
      visibility: visible;
      transform: translateX(-56px);
    }
  }
}
</style>
