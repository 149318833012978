<template lang="pug">
li.c-side-drawer-nav__item(:class='classObj')
  a.c-side-drawer-nav__link(:href="href || '#'" :target="openNewTab ? '_blank' : null" :rel="openNewTab ? 'noopener' : null" @click='clickHandler')
    span.c-side-drawer-nav__link__icon(v-if='hasIconLeft' :class='iconLeft')
    span.c-side-drawer-nav__link__label(:class='labelClass') {{ label }}
    span.c-side-drawer-nav__link__icon--right(v-if='hasIconRight' :class='iconRightClass')
  SideDrawerNav(v-if='hasSubnav && subnavActive' :items='subnav' :subnav='true')
</template>

<script>
export default {
  name: 'SideDrawerNavItem',
  props: {
    label: {
      type: String,
      required: true,
    },
    labelClass: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    subnav: {
      type: Array,
      default: () => [],
    },
    onClick: {
      type: Function,
      default: null,
    },
    openNewTab: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    subnavActive: false,
  }),
  computed: {
    hasSubnav() {
      return typeof this.subnav !== 'undefined' && this.subnav.length > 0;
    },
    hasIconLeft() {
      return !!this.iconLeft;
    },
    hasIconRight() {
      return this.hasSubnav || !!this.iconRight;
    },
    iconRightClass() {
      return this.hasSubnav ? 'c-icon c-icon--chevron-down' : this.iconRight;
    },
    classObj() {
      return {
        'c-side-drawer-nav__item--has-subnav': this.hasSubnav,
        'subnav-active': this.subnavActive,
      };
    },
  },
  methods: {
    clickHandler(e) {
      if (this.hasSubnav) {
        e.preventDefault();
        this.subnavActive = !this.subnavActive;
      } else if (this.onClick) {
        this.onClick.call(this, e); // eslint-disable-line no-useless-call
      }
    },
  },
};
</script>
