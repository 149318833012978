<template lang="pug">
SideDrawerNav(:items="dynamicNavItems")
</template>

<script>
import { isHuaweiUserAgent } from '../../../../shared/javascripts/useragent-helper';
import { runtimeEnv } from '../../../../shared/javascripts/runtime-parser';

export default {
  name: 'SideDrawerMainNav',
  data: () => ({
    isMounted: false,
    navItems: [
      {
        label: 'E-Voucher',
        labelClass: 'qa-sidebar-evoucher-menu',
        iconLeft:
          'c-sprite-icon c-sprite-icon--e_voucher c-sprite-icon--medium',
        iconRight: 'c-icon c-icon--chevron-right',
        onClick: function showCategoryNav(e) {
          // NOTE: 'this' context in this function will refer to SideDrawerNavItem component
          e.preventDefault();
          this.$store.commit('app/setSideDrawerNav', 'vp');
        },
      },
      {
        label: 'Kategori',
        labelClass: 'qa-sidebar-category-menu',
        iconLeft: 'c-sprite-icon c-sprite-icon--list c-sprite-icon--medium',
        iconRight: 'c-icon c-icon--chevron-right',
        onClick: function showCategoryNav(e) {
          e.preventDefault();
          this.$store.commit('app/setSideDrawerNav', 'category');
        },
      },
      {
        label: 'Promo',
        iconLeft: 'c-sprite-icon c-sprite-icon--promo c-sprite-icon--medium',
        href: 'https://m.bukalapak.com/promo',
      },
      {
        label: 'Lebih Hemat di Aplikasi',
        iconLeft: 'c-sprite-icon c-sprite-icon--phone c-sprite-icon--medium',
        href: isHuaweiUserAgent()
          ? 'https://appgallery.cloud.huawei.com/ag/n/app/C103354495?locale=en_GB&source=appshare&subsource=C103354495'
          : 'https://bl.app.link/jROU2eKOxK',
      },
      {
        label: 'Cek Transaksi',
        iconLeft:
          'c-sprite-icon c-sprite-icon--transaction c-sprite-icon--medium',
        href: 'https://m.bukalapak.com/payment/invoices?from=side_nav',
      },
      {
        label: 'BukaReview',
        iconLeft:
          'c-sprite-icon c-sprite-icon--medium c-sprite-icon--bukareview',
        href: 'https://review.bukalapak.com',
      },
      {
        label: 'BukaBantuan (Hubungi Kami)',
        iconLeft:
          'c-sprite-icon c-sprite-icon--customer-service c-sprite-icon--medium',
        href: 'https://bukabantuan.bukalapak.com',
      },
      {
        label: 'FAQ (Tanya Jawab)',
        iconLeft: 'c-sprite-icon c-sprite-icon--help c-sprite-icon--medium',
        href: 'https://m.bukalapak.com/faq',
      },
      {
        label: 'Lainnya',
        iconLeft: 'c-sprite-icon c-sprite-icon--others c-sprite-icon--medium',
        subnav: [
          {
            label: 'Credits Gratis Rp200.000',
            href: 'https://m.bukalapak.com/referral',
          },
          {
            label: 'Komunitas',
            href: 'https://komunitas.bukalapak.com',
          },
          {
            label: 'Blog',
            href: 'https://blog.bukalapak.com/',
          },
          {
            label: 'Aturan Penggunaan',
            href: 'https://m.bukalapak.com/terms',
          },
        ],
      },
    ],
  }),
  computed: {
    dynamicNavItems() {
      if (!this.isMounted) {
        return this.navItems;
      }

      const logoutMenu = {
        label: 'Logout',
        iconLeft: 'c-sprite-icon c-sprite-icon--logout c-sprite-icon--medium',
        href: runtimeEnv.ACCOUNTS_URL
          ? `${runtimeEnv.ACCOUNTS_URL}/logout`
          : 'https://accounts.bukalapak.com/logout',
      };

      const loginMenu = {
        label: 'Login',
        iconLeft: 'c-sprite-icon c-sprite-icon--login c-sprite-icon--medium',
        href: runtimeEnv.ACCOUNTS_URL
          ? `${runtimeEnv.ACCOUNTS_URL}/login`
          : 'https://accounts.bukalapak.com/login',
      };

      return global.USER && global.USER.id
        ? [...this.navItems, logoutMenu]
        : [loginMenu, ...this.navItems];
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
