import categoryModule from './category';

export const appState = {
  showSideDrawer: null,
  showProfileOverlay: false,
  sideDrawerNav: 'main',
};

export const mutations = {
  toggleSideDrawer(state) {
    state.showSideDrawer = !state.showSideDrawer;
  },
  toggleProfileOverlay(state) {
    state.showProfileOverlay = !state.showProfileOverlay;
  },
  setSideDrawerNav(state, nav) {
    state.sideDrawerNav = nav;
  },
};

export default ({ API }) => ({
  modules: {
    app: {
      namespaced: true,
      state: appState,
      mutations,
    },
    category: categoryModule({ API }),
  },
});
