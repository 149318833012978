export const isHuaweiUserAgent = () => {
  const HUAWEI_USER_AGENT = 'huawei';
  return (
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.userAgent
      .toLowerCase()
      .indexOf(HUAWEI_USER_AGENT.toLowerCase()) >= 0
  );
};

export default {
  isHuaweiUserAgent,
};
