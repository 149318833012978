export function parseRuntimeEnv(className) {
  if (process.server) return {};

  const fragment = document.getElementsByClassName(className)[0];
  let runtimeEnv = {};

  if (fragment) {
    const runtimeEnvString = fragment.getAttribute('data-runtime-env');

    try {
      runtimeEnv = JSON.parse(decodeURIComponent(runtimeEnvString));
    } catch (e) {
      // invalid runtime-env data attribute
    }
  }

  return runtimeEnv;
}

export const runtimeEnv = parseRuntimeEnv('sigil');
